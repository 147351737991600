export const LOGIN_URL = `${process.env.REACT_APP_BACKEND_URL}/users/login/`;
export const USER_ME_URL = `${process.env.REACT_APP_BACKEND_URL}/users/me/`;
export const USERS_LISTING_URL = `${process.env.REACT_APP_BACKEND_URL}/users/`;

export const ALL_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/all/`;
export const AWAITING_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/waiting/`;
export const REVOKE_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/revoke/`;
export const REJECT_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/reject/`;
export const ACCEPT_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/accept/`;
export const OFFERS_REWARD_URL = `${process.env.REACT_APP_BACKEND_URL}/offers/reward/`;
export const OFFERS_COUNTRIES_URL = `${process.env.REACT_APP_BACKEND_URL}/configurations/supported-countries/`;

export const UPLOAD_OFFERS_URL = `${process.env.REACT_APP_BACKEND_URL}/offer-sources/upload/`;
export const VALIDATE_OFFERS_URL = id => `${process.env.REACT_APP_BACKEND_URL}/offer-sources/validate/${id}/`;
export const SAVE_OFFERS_URL = id => `${process.env.REACT_APP_BACKEND_URL}/offer-sources/save/${id}/`;
export const OFFERS_POLLING_URL = id => `${process.env.REACT_APP_BACKEND_URL}/offer-sources/${id}/`;
export const REVOKE_VALIDATE_ATTACHMENT_URL = `${process.env.REACT_APP_BACKEND_URL}/offer-sources/revoke-task/`;

export const OFFERS_BUDGET_URL = `${process.env.REACT_APP_BACKEND_URL}/budgets/`;

export const CONFIGURATIONS_URL = `${process.env.REACT_APP_BACKEND_URL}/configurations/`;
export const CONFIGURATIONS_CHOICES_URL = `${process.env.REACT_APP_BACKEND_URL}/configurations/choices/`;
