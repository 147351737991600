import React, { useEffect } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/Constants';
import { setLocalStorageItem } from '../../utils/utils';

const ConfigurationsTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if ([ROUTES.CONFIGURATIONS, `${ROUTES.CONFIGURATIONS}/`].includes(location.pathname))
      navigate(ROUTES.CONFIGURATIONS_OFFERS_BUDGET);
    else setLocalStorageItem('configurationsPathname', location.pathname);
  }, [location]);

  return (
    <div className='configurations-tabs'>
      <div className='tabs'>
        <NavLink to={ROUTES.CONFIGURATIONS_OFFERS_BUDGET}>Offers budget</NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default ConfigurationsTabs;
