import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { PaginationWrapper } from '../../common/PaginationWrapper';
import { RejectAll, AcceptAll, SortDecending, SortAscending } from '../../svgs/components';
import { useAddMutation, useGetRequest, usePostRequest } from '../../hooks/useRequests';
import {
  BACKEND_DATE_FORMAT,
  DATE_FORMAT,
  OFFER_TYPES,
  PAGINATION_OFFSET,
  CAN_APPROVE_OFFERS,
  CAN_DECLINE_OFFERS,
  BASE_OPTION_ID,
  SUBMISSION_DATE_PARAM,
  SYSTEM_ID_PARAM,
  OFFER_TYPE_PARAM,
  USER_PARAM,
  DESC_ORDER,
  ASC_ORDER,
  OFFER_ORDERING_PARAM,
  TOAST_ERROR_STATUS,
  ENTER,
  COUNTRY_CODE_PARAM,
} from '../../utils/constants';
import OffersModal from './OffersModal';
import moment from 'moment';
import {
  addOrUpdateURLParam,
  addOrUpdateURLParamForFilters,
  deleteURLParamForFilter,
  validatePermission,
  raiseToast,
  handleSelectItem,
  raiseToastOnAction,
} from '../../utils/utils';
import Filters from './Filters';
import {
  ACCEPT_OFFERS_URL,
  ALL_OFFERS_URL,
  AWAITING_OFFERS_URL,
  CONFIGURATIONS_URL,
  OFFERS_COUNTRIES_URL,
  OFFERS_REWARD_URL,
  REJECT_OFFERS_URL,
  USERS_LISTING_URL,
} from '../../utils/urls';
import Loader from '../../common/Loader';
import {
  OFFERS_SUCCESS_APPROVED_MESSAGE,
  OFFERS_SUCCESS_REJECTED_MESSAGE,
  INDIVIDUAL_OFFER_SUCCESS_APPROVED_MESSAGE,
  INDIVIDUAL_OFFER_SUCCESS_REJECTED_MESSAGE,
  AWAITING_APPROVAL_REJECTED_MESSAGE,
  OFFERS_REJECTED_MESSAGE,
} from '../../utils/messages';

const AwaitingApprovalTable = ({ permissions }) => {
  const [selectedItem, setSelectItem] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showOffersModal, setShowOffersModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [singleSelection, setSingleSelection] = useState({});
  const [pageOffset, setPageOffset] = useState(PAGINATION_OFFSET.PAGINATION_OFFSET_10);
  const [pageNo, setPageNo] = useState(1);
  const [awaitingOffersURL, setAwaitingOffersURL] = useState(
    `${AWAITING_OFFERS_URL}?page=${pageNo}&page_size=${pageOffset}`
  );
  const [selectedCountry, setSelectedCountry] = useState('');
  const [systemId, setSystemId] = useState();
  const [systemIdText, setSystemIdText] = useState('');
  const [submissionDate, setSubmissionDate] = useState();
  const [offerType, setOfferType] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [order, setOrder] = useState(DESC_ORDER);

  const [showBulkRejectionModal, setShowBulkRejectionModal] = useState(false);
  const [showBulkApprovalModal, setShowBulkApprovalModal] = useState(false);

  const canApproveOffer = validatePermission(CAN_APPROVE_OFFERS, permissions);
  const canDeclineOffer = validatePermission(CAN_DECLINE_OFFERS, permissions);
  const [currency, setCurrency] = useState('');
  const [rewardAmount, setRewardAmount] = useState('');

  const { data: offers, isFetching } = useGetRequest('awaiting-offers', [], awaitingOffersURL, null);

  const { data: users } = useGetRequest('users', [], USERS_LISTING_URL, null);

  const { data: configuration } = useGetRequest('configuration-offers', [], CONFIGURATIONS_URL, null);

  const { data: countries, isFetching: countriesLoading } = useGetRequest(
    'configurations-supported-countries',
    [],
    OFFERS_COUNTRIES_URL
  );

  const { refetch: paginateAllOffers } = useGetRequest('awaiting-offers', [], awaitingOffersURL, null, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });

  const { refetch: updateAllOffers } = useGetRequest(
    'all-offers',
    [],
    `${ALL_OFFERS_URL}?page=1&page_size=${PAGINATION_OFFSET.PAGINATION_OFFSET_10}`,
    null,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  const {
    data: reward,
    refetch: getRewardAmount,
    isFetching: isRewardLoading,
  } = usePostRequest(
    'awaiting-approval-reward',
    [],
    OFFERS_REWARD_URL,
    {
      ids: selectedItem,
    },
    null,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  const handleCheckBox = (e, id) => {
    setSelectItem(handleSelectItem(selectedItem, id));
  };

  const handleSelectAll = () => {
    let ids = offers?.data?.ids;
    const allSelect = ids?.every(id => selectedItem.includes(id));

    if (allSelect) {
      setIsChecked(false);
      setSelectItem([]);
    } else {
      setIsChecked(true);
      setSelectItem(offers?.data?.ids);
    }
  };

  useEffect(() => {
    let ids = offers?.data?.ids;
    const allSelect = ids?.every(id => selectedItem.includes(id));
    if (allSelect && ids?.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedItem, offers]);

  useEffect(() => {
    setRewardAmount(reward?.data?.offers_reward);
  }, [reward]);

  useEffect(() => {
    setCurrency(configuration?.data?.results[0]?.currency);
  }, [configuration]);

  const onSuccessSingleAccept = data => {
    raiseToastOnAction(
      data,
      INDIVIDUAL_OFFER_SUCCESS_APPROVED_MESSAGE,
      AWAITING_APPROVAL_REJECTED_MESSAGE,
      singleSelection.id
    );
    setShowOffersModal(false);
    setSingleSelection({});
    setSelectItem([]);
    updateAllOffers();
  };

  const onSuccessSingleReject = data => {
    raiseToastOnAction(
      data,
      INDIVIDUAL_OFFER_SUCCESS_REJECTED_MESSAGE,
      AWAITING_APPROVAL_REJECTED_MESSAGE,
      singleSelection.id
    );
    setShowOffersModal(false);
    setSingleSelection({});
    setSelectItem([]);
  };

  const onSuccessBulkAccept = data => {
    raiseToastOnAction(data, OFFERS_SUCCESS_APPROVED_MESSAGE, OFFERS_REJECTED_MESSAGE);
    setSelectItem([]);
    setSingleSelection({});
    setShowBulkApprovalModal(false);
    updateAllOffers();
  };

  const onSuccessBulkReject = data => {
    raiseToastOnAction(data, OFFERS_SUCCESS_REJECTED_MESSAGE, OFFERS_REJECTED_MESSAGE);
    setSelectItem([]);
    setSingleSelection({});
    setShowBulkRejectionModal(false);
  };

  const onFailure = error => {
    raiseToast(TOAST_ERROR_STATUS, OFFERS_REJECTED_MESSAGE(error?.data?.failed));
    setShowOffersModal(false);
    setShowBulkRejectionModal(false);
    setShowBulkApprovalModal(false);
  };

  const { mutate: handleSingleOfferRejection, isLoading: loadingsingleOffersRejection } = useAddMutation(
    'awaiting-offers',
    [],
    REJECT_OFFERS_URL,
    { ids: [singleSelection.id] },
    null,
    onSuccessSingleReject,
    onFailure
  );

  const { mutate: handleSingleOfferAcceptance, isLoading: loadingsingleOffersAcceptance } = useAddMutation(
    'awaiting-offers',
    [],
    ACCEPT_OFFERS_URL,
    { ids: [singleSelection.id] },
    null,
    onSuccessSingleAccept,
    onFailure
  );

  const { mutate: handleBulkOffersRejection, isLoading: loadingBulkOffersRejection } = useAddMutation(
    'awaiting-offers',
    [],
    REJECT_OFFERS_URL,
    { ids: selectedItem },
    null,
    onSuccessBulkReject,
    onFailure
  );

  const { mutate: handleBulkOffersAcceptance, isLoading: loadingBulkOffersAcceptance } = useAddMutation(
    'awaiting-offers',
    [],
    ACCEPT_OFFERS_URL,
    { ids: selectedItem },
    null,
    onSuccessBulkAccept,
    onFailure
  );

  const singleOfferRejection = () => {
    handleSingleOfferRejection();
  };

  const singleOfferAcceptance = () => {
    handleSingleOfferAcceptance();
  };

  const handleCancelBtn = (e, item) => {
    e.preventDefault();
    setSingleSelection(item);
    setShowOffersModal(true);
    setIsCancel(true);
  };

  const handleConfirmBtn = (e, item) => {
    e.preventDefault();
    setSingleSelection(item);
    setShowOffersModal(true);
    setIsCancel(false);
  };

  const handleBulkAccept = () => {
    getRewardAmount();
    setShowBulkApprovalModal(true);
  };

  const handleBulkReject = () => {
    getRewardAmount();
    setShowBulkRejectionModal(true);
  };

  const handleConfirmationModalClear = () => {
    setShowOffersModal(false);
  };

  const handleBulkConfirmationModalClear = () => {
    setSelectItem([]);
    setShowBulkRejectionModal(false);
    setShowBulkApprovalModal(false);
  };

  useEffect(() => {
    let url = addOrUpdateURLParam(awaitingOffersURL, 'page', pageNo);
    url = addOrUpdateURLParam(url, 'page_size', pageOffset);
    setAwaitingOffersURL(url);
  }, [pageNo, pageOffset]);

  useEffect(() => {
    paginateAllOffers();
  }, [awaitingOffersURL]);

  const handlePageOffset = offset => {
    setPageOffset(offset?.target?.value);
    setPageNo(1);
  };

  const handlePagination = ({ selected }) => {
    setPageNo(selected + 1);
  };

  const handleSearchFilterKeyPress = e => {
    if (e.key === ENTER) {
      if (e.target.value === '') {
        setAwaitingOffersURL(deleteURLParamForFilter(awaitingOffersURL, 'main_system_ids'));
      }
      setSystemId(systemIdText);
    }
  };

  const handleSearchFilterChange = e => {
    setSystemIdText(e.target.value);
  };

  const handleStartDateChange = date => {
    setSubmissionDate(date);
  };

  const handleOfferTypeChange = e => {
    setOfferType(e.target.value);
  };

  const handleUserChange = e => {
    setSelectedUser(e.target.value);
  };

  const searchFilter = {
    placeholder: 'Search by Retailer ID (comma separated)',
    handleKeyPress: handleSearchFilterKeyPress,
    handleChange: handleSearchFilterChange,
    value: systemIdText,
  };

  const startDateFilter = {
    placeholder: 'Submission date',
    handleChange: handleStartDateChange,
    date: submissionDate,
  };

  const typeFilter = {
    placeholder: 'Offer type',
    handleChange: handleOfferTypeChange,
    offerTypes: OFFER_TYPES,
    offer: offerType,
  };

  const userFilter = {
    placeholder: 'User',
    handleChange: handleUserChange,
    users: users?.data?.results,
    selectedUser: selectedUser,
  };

  const countryFilter = {
    placeholder: 'Country',
    selectedItem: selectedCountry,
    setSelectedItem: setSelectedCountry,
    countries: countries?.data?.map(country => ({ label: country?.country_code, value: country?.id })),
  };

  const handleOrderChange = () => {
    setOrder(order === DESC_ORDER ? ASC_ORDER : DESC_ORDER);
  };

  const clearFilter = () => {
    let newUrl = awaitingOffersURL;
    setOfferType(undefined);
    setSystemIdText('');
    setSystemId(undefined);
    setSubmissionDate(undefined);
    setSelectedUser(undefined);
    newUrl = deleteURLParamForFilter(newUrl, SUBMISSION_DATE_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, OFFER_TYPE_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, SYSTEM_ID_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, USER_PARAM);
    newUrl = deleteURLParamForFilter(newUrl, COUNTRY_CODE_PARAM);
    setAwaitingOffersURL(newUrl);
    selectedCountry && setSelectedCountry('');
  };

  useEffect(() => {
    if (submissionDate || selectedUser || systemId || offerType || selectedCountry) {
      setPageNo(1);
    }
    let newUrl = awaitingOffersURL;
    if (submissionDate) {
      newUrl = addOrUpdateURLParamForFilters(
        newUrl,
        SUBMISSION_DATE_PARAM,
        moment(submissionDate).format(BACKEND_DATE_FORMAT)
      );
    }
    if (systemId) {
      newUrl = addOrUpdateURLParamForFilters(newUrl, SYSTEM_ID_PARAM, systemId);
    }
    if (offerType) {
      newUrl =
        offerType === BASE_OPTION_ID
          ? deleteURLParamForFilter(newUrl, OFFER_TYPE_PARAM)
          : addOrUpdateURLParamForFilters(newUrl, OFFER_TYPE_PARAM, offerType);
    }
    if (selectedUser) {
      newUrl =
        selectedUser === BASE_OPTION_ID
          ? deleteURLParamForFilter(newUrl, USER_PARAM)
          : addOrUpdateURLParamForFilters(newUrl, USER_PARAM, selectedUser);
    }
    if (order) {
      newUrl = addOrUpdateURLParamForFilters(newUrl, OFFER_ORDERING_PARAM, order);
    }

    if (selectedCountry) newUrl = addOrUpdateURLParamForFilters(newUrl, COUNTRY_CODE_PARAM, selectedCountry?.label);
    if (newUrl && newUrl !== awaitingOffersURL) {
      setAwaitingOffersURL(newUrl);
    }
  }, [submissionDate, offerType, systemId, selectedUser, order, selectedCountry]);

  useEffect(() => {
    if (systemIdText === '') {
      setAwaitingOffersURL(deleteURLParamForFilter(awaitingOffersURL, 'main_system_ids'));
      setSystemId('');
    }
  }, [systemIdText]);

  return (
    <>
      {isFetching || countriesLoading ? (
        <Loader />
      ) : (
        <>
          <Filters
            searchFilter={searchFilter}
            startDateFilter={startDateFilter}
            userFilter={userFilter}
            typeFilter={typeFilter}
            countryFilter={countryFilter}
          />
          {(submissionDate ||
            systemIdText ||
            selectedCountry ||
            (selectedUser === BASE_OPTION_ID ? undefined : selectedUser) ||
            (offerType === BASE_OPTION_ID ? undefined : offerType)) && (
            <div className='d-flex justify-content-end mt-4'>
              <span className='clear-filter' onClick={clearFilter}>
                Clear Filter
              </span>
            </div>
          )}
          <div className='awaiting-approval-table'>
            <Table>
              <thead>
                <tr className='bulk-selected' key='awaiting-offers-filter'>
                  {selectedItem && selectedItem?.length > 0 && (canApproveOffer || canDeclineOffer) && (
                    <th colSpan={9}>
                      <div className='all-checked'>
                        {`${selectedItem?.length} ID${selectedItem?.length > 1 ? 's' : ''} selected`}
                        {canDeclineOffer && (
                          <a onClick={handleBulkReject} className='revoked-all'>
                            <span>
                              <RejectAll />
                            </span>
                            {`Reject ${selectedItem?.length} offer${selectedItem?.length > 1 ? 's' : ''}`}
                          </a>
                        )}
                        {canApproveOffer && (
                          <a onClick={handleBulkAccept} className='accept-all'>
                            <span>
                              <AcceptAll />
                            </span>
                            {`Accept ${selectedItem?.length} offer${selectedItem?.length > 1 ? 's' : ''}`}
                          </a>
                        )}
                      </div>
                    </th>
                  )}
                </tr>
                <tr key='awaiting-offers-header'>
                  <th className='w-13'>
                    <div className='sort-id'>
                      <Form.Check
                        className='checkbox'
                        disabled={!offers?.data?.ids?.length}
                        onChange={handleSelectAll}
                        checked={isChecked}
                      />
                      <p>ID</p>
                      <div onClick={handleOrderChange}>
                        {order === DESC_ORDER ? <SortDecending /> : <SortAscending />}
                      </div>
                    </div>
                  </th>
                  <th className='w-10'>Retailer ID</th>
                  <th className='w-11'>Submission</th>
                  <th className='w-11'>Start & End</th>
                  <th className='w-11'>Reward</th>
                  <th className='w-11'>Based on</th>
                  <th className='w-9'>Goal</th>
                  <th className='w-8'>Country</th>
                  <th className='w-10'>Offer Type</th>
                  {(canApproveOffer || canDeclineOffer) && <th className='w-3 text-center'>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {offers?.data?.results?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td className='itemId w-12'>
                        <div className='d-flex '>
                          <Form.Check
                            className='checkbox'
                            onChange={e => handleCheckBox(e, item.id)}
                            checked={selectedItem.includes(item.id)}
                          />
                          #{item.id}
                        </div>
                      </td>
                      <td className='grey w-10'>#{item.main_system_id}</td>
                      <td className='w-11'>
                        #{item?.sheet?.submitted_by}
                        <span>{moment(item.sheet.created).format(DATE_FORMAT)}</span>
                      </td>
                      <td className='w-11'>
                        {moment(item.start_date).format(DATE_FORMAT)}
                        <span>{moment(item.end_date).format(DATE_FORMAT)}</span>
                      </td>
                      <td className='w-11'>
                        {item?.reward} {currency}
                        <span>{item?.reward_type_display}</span>
                      </td>
                      <td className='grey w-11'>
                        {item?.goals.length > 1 ? (
                          <span className='grey'>Multiple</span>
                        ) : (
                          item?.goals?.map(goal => (
                            <div key={goal?.id}>
                              <span className='grey'>{goal?.based_on_display}</span>
                            </div>
                          ))
                        )}
                      </td>
                      <td className='w-10'>
                        {item?.goals.length > 1 ? (
                          <span>Multiple</span>
                        ) : (
                          item?.goals?.map(goal => (
                            <div key={goal?.id}>
                              {goal?.target} {currency}
                            </div>
                          ))
                        )}
                      </td>
                      <td className='w-8'>{item?.country_code}</td>
                      <td className='w-10'>
                        {item.offer_type_display}
                        <span>{item.arabic_name}</span>
                      </td>
                      {(canApproveOffer || canDeclineOffer) && (
                        <td className='w-3'>
                          <div className='d-flex justify-content-center'>
                            {canDeclineOffer && (
                              <span>
                                <a className='action cancel-btn' onClick={e => handleCancelBtn(e, item)} href='#'>
                                  <RejectAll />
                                </a>
                              </span>
                            )}
                            {canApproveOffer && (
                              <span>
                                <a className='action accept-btn' onClick={e => handleConfirmBtn(e, item)} href='#'>
                                  <AcceptAll />
                                </a>
                              </span>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr key='awaiting-offers-pagination'>
                  <td colSpan={9}>
                    <PaginationWrapper
                      currentPage={parseInt(pageNo)}
                      total={offers?.data?.count}
                      pageOffset={pageOffset}
                      handlePagination={handlePagination}
                      handlePageOffset={handlePageOffset}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      )}
      {showOffersModal && (
        <OffersModal
          showOffersModal={showOffersModal}
          handleClose={handleConfirmationModalClear}
          handleConfirmation={isCancel ? singleOfferRejection : singleOfferAcceptance}
          isCancel={isCancel}
          message={
            isCancel
              ? `You are about to reject offer with total reward amount ${singleSelection.reward}  ${currency}`
              : `You are about to accept offer with total reward amount ${singleSelection.reward}  ${currency}`
          }
          heading={isCancel ? 'Reject Offer' : 'Accept Offer'}
          currency={currency}
          loading={isCancel ? loadingsingleOffersRejection : loadingsingleOffersAcceptance}
        />
      )}
      {(showBulkRejectionModal || showBulkApprovalModal) && (
        <OffersModal
          showOffersModal={showBulkRejectionModal || showBulkApprovalModal}
          handleClose={handleBulkConfirmationModalClear}
          handleConfirmation={showBulkRejectionModal ? handleBulkOffersRejection : handleBulkOffersAcceptance}
          isCancel={showBulkRejectionModal}
          message={
            showBulkRejectionModal
              ? `You are about to reject offer${
                  selectedItem?.length > 1 ? 's' : ''
                } with total reward amount ${rewardAmount}  ${currency}`
              : `You are about to accept offer${
                  selectedItem?.length > 1 ? 's' : ''
                } with total reward amount ${rewardAmount}  ${currency}`
          }
          heading={
            showBulkRejectionModal
              ? `Reject Offer${selectedItem?.length > 1 ? 's' : ''}`
              : `Accept Offer${selectedItem?.length > 1 ? 's' : ''}`
          }
          currency={currency}
          loading={showBulkRejectionModal ? loadingBulkOffersRejection : loadingBulkOffersAcceptance}
          isRewardLoading={isRewardLoading}
        />
      )}
    </>
  );
};

export default AwaitingApprovalTable;
